<template>
  <dataDictContainer>
    <Nav
      :FileName="file_data.metadata.name"
      background="transparent"
      style="grid-area: head"
      :Logo="baseUrl + configTheme.logo"
    />
    <div class="nav-menu" style="grid-area: user">
      <!-- <Gridmenu /> -->
      <User :user="profile" @getToken="getToken" />
      <DialogToken
        @closeDialog="getToken"
        :token="token"
        :dialog="tokenDialog"
      />
    </div>
    <Sidebar class="b-r" style="grid-area: side">
      <NavigateDict :Profiling="profiling.profile" />
    </Sidebar>
    <DataDictionaryDisplay
      :fileId="file_data.id"
      :fileName="file_data.metadata.name"
      :description="file_data.metadata.description"
      :filePath="path"
      :context="file_data.context"
      :records="computedRecords"
    />
  </dataDictContainer>
</template>

<script>
import {
  DataDictionaryDisplay,
  Nav,
  Sidebar,
  // DictContainer,
  NavigateDict,
  dataDictContainer,
} from "../components/styleComponent";
import { mapState } from "vuex";
import User from "../components/Navbar/User.vue";
import DialogToken from "../components/Navbar/DialogToken.vue";
import parquetIcon from "@/assets/parquetLogo.png";
export default {
  components: {
    NavigateDict,
    DataDictionaryDisplay,
    Nav,
    Sidebar,
    // DictContainer,
    dataDictContainer,
    User,
    DialogToken,
  },
  data() {
    return {
      tokenDialog: false,
      token: localStorage.getItem("token"),
      baseUrl: process.env.VUE_APP_DOMAIN,
      favicon: parquetIcon,
      path: [],
      fileData: {},
    };
  },
  computed: {
    ...mapState({
      filePath: (state) => state.currentPath,
      profile: (state) => state.user,
      configTheme: (state) => state.configTheme,
      profiling: (state) => state.profiling,
      file_data: (state) => state.metadata,
      computedRecords: (state) =>
        state.metadata?.context?.statistics?.after_validate_stat?.all_record ||
        null,
    }),
  },
  mounted() {
    document.title = "DataDictionary";
    const favicon = document.getElementById("favicon");
    favicon.href = this.favicon;
    this.$store.dispatch("getUserProfile");
    let fileRetive = {
      type: 2,
      id: this.$route.params.id,
      route: this.$route.name,
    };
    this.$store.dispatch("getMetadataByID", fileRetive);
    this.$store.dispatch("getDatadictionary", this.$route.params.id);
    this.$store.dispatch("getProfiling", this.$route.params.id);
    // this.fileData = JSON.parse(localStorage.getItem("fileData"));
  },
  methods: {
    getToken(val) {
      console.log("getTOken", val);
      val === this.tokenDialog
        ? (this.tokenDialog = !this.tokenDialog)
        : (this.tokenDialog = val);
    },
  },
  watch: {
    fileRetive: {
      handler(val) {
        console.log("fileRetive", val);
        if (val) {
          const pathSplit = this.fileRetive.path.split("/");
          const pathFormatt = pathSplit.map((i) => {
            return {
              id: i,
              text: i,
              disabled: true,
            };
          });
          this.path = pathFormatt;
        }
        let retivePayload = {
          id: val.directory,
          path: this.$route.name,
        };
        this.$store.dispatch("getDirectory", retivePayload);
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.nav-menu {
  display: flex;
  align-items: center;
  padding: 0 1.25em;
  border-bottom: 1px solid #ccc;
}

@media print {
  .nav-menu {
    display: none;
  }
}
</style>
